import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewLogo from "../Images/npai_logo_curves.svg";
import ThreeArrow from "../Images/ThreeArrow.png";
import AboutLogo from "../Images/sidebarlogo/1.svg";
import ProductsLogo from "../Images/sidebarlogo/11.svg";
import servicesLogo from "../Images/sidebarlogo/12.svg";
import Email from "../Images/sidebarlogo/3.svg";
import PressLogo from "../Images/sidebarlogo/4.svg";
import Contact from "../Images/contact.png";
import FAQsLogo from "../Images/sidebarlogo/7.svg";
import ClientLogo from "../Images/sidebarlogo/8.svg";
import BackersLogo from "../Images/sidebarlogo/9.svg";
import LegalLogo from "../Images/sidebarlogo/10.svg";
import Arrow from "../Images/arrow.png";

import { items } from "./data";
import About from "./SideNavBar/About";
import Products from "./SideNavBar/Products.js";
import Services from "./SideNavBar/Services.js";
import Backers from "./SideNavBar/Backers";
import Client from "./SideNavBar/Client.js";
import ContactForm from "./SideNavBar/ContactForm.js";

import Press from "./SideNavBar/PressViewer.js";
import Faq from "./SideNavBar/Faq.js";
import "./Home.css";

import unleash from "../Images/unleash.png";
import unleashMobile from "../Images/Unleash_mobile.png";
import mobileAsset from "../Images/MobileAsset.png";
import Legal from "./SideNavBar/Legal.js";

export default function Home() {
  const [hoveredImage, setHoveredImage] = useState(items[0].input);
  const [hoveredImageOutput, setHoveredImageOutput] = useState(items[0].output);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [autoHovering, setAutoHovering] = useState(true);
  const [style, setStyle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const navigate = useNavigate();

  console.log("neuropixel.ai");
  useEffect(() => {
    let interval;
    if (autoHovering) {
      interval = setInterval(() => {
        const nextIndex = (currentItemIndex + 1) % items.length;
        setCurrentItemIndex(nextIndex);
        setHoveredImage(items[nextIndex].input);
        setHoveredImageOutput(items[nextIndex].output);
      }, 1500);
    }
    return () => clearInterval(interval);
  }, [autoHovering, currentItemIndex]);

  useEffect(() => {
    setStyle(
      autoHovering || currentItemIndex !== -1 ? "text-white font-bold" : ""
    );
  }, [autoHovering, currentItemIndex]);

  const handleMouseEnter = () => {
    setAutoHovering(false);
  };

  const handleMouseLeave = () => {
    setAutoHovering(true);
  };

  const handleItemMouseEnter = (index) => {
    setAutoHovering(false);
    setCurrentItemIndex(index);
    setHoveredImage(items[index].input);
    setHoveredImageOutput(items[index].output);
  };

  const handleItemMouseLeave = () => {
    setCurrentItemIndex(-1);
  };

  const handleItemClick = (link) => {
    navigate(link);
  };

  const handleLogoClick = (link) => {
    navigate("/");
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleMenuItemClick = (sectionId, scrollToSection) => {
    setSelectedMenuItem(sectionId);

    scrollToSection();
  };

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };

  const handleCombinedClick = () => {
    toggleModal();
    setTimeout(() => {
      handleMenuItemClick("contact", () => scrollToSection("contact"));
    }, 0);
  };

  return (
    <>
      <div className="invisible md:visible  w-0 h-0 md:w-full md:h-full gradient-div-home">
        <img
          src={unleash}
          alt=""
          className="fixed left-[-24.5vw] top-[-5vw] !w-[150vw] "
        />
        {/* <img src={Assest2} alt="" className="fixed left-[13vw] top-[0.5vw] w-[32vw]"/> */}
      </div>

      {/*Laptop Screen Only */}
      <div
        className={`invisible md:visible w-0 h-0 md:w-full md:h-full md:transition-all md:duration-[2s] text-black ${
          isModalOpen ? "md:transform md:translate-x-[55vw]" : ""
        }`}
        style={{
          backgroundColor: isModalOpen ? "bg-[#FFFDF3]" : "",
          fontFamily: "Poppins",
        }}
      >
        <div
          className="w-[100vw] bg-[#E10600] h-[2vw] flex justify-center items-center text-white text-[1vw] cursor-pointer"
          onClick={() => {
            window.open("https://forms.gle/8ainDg6M5yNWvHLd6", "_blank");
          }}
        >
          We're launching our self-service platform in September.{"  "}
          <span className="text-white hover:underline cursor-pointer px-[0.4vw]">
            Click HERE
          </span>
          {"   "}
          to join our Early Access program and get $10 in free credits.
        </div>

        <div
          className={`relative w-full !h-screen flex flex-col md:flex-row justify-start items-start md:text-[1.25rem] lg:text-[1.35rem] xl:text-[2.50rem] overflow-hidden`}
        >
          <div className="md:max-w-[55.5vw] md:mx-auto h-[55vh] w-full block relative md:!h-[95vw] pl-0 pr-0">
            <div className="top-0 left-0 z-10">
              <div className="flex justify-between p-[0.4vw] pl-0  pt-[0.7vw]">
                {/* Add onClick handler to open modal */}
                <div className="flex gap-[2vw] !pl-[0.9vw]">
                  <div
                    className="flex pt-[3vw] md:pt-[1.3vw]"
                    onClick={toggleModal}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={ThreeArrow}
                      alt=""
                      className="w-[6vw] h-[5vw] md:w-[2vw] md:h-[1.5vw]"
                    />
                  </div>
                  <div
                    className="flex pl-[0.5vw]"
                    onClick={handleLogoClick}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={NewLogo}
                      alt=""
                      className="w-[50vw] h-auto md:w-[15vw]"
                    />
                  </div>
                </div>

                <div
                  className="flex justify-end items-center md:pb-[1vw]"
                  onClick={handleCombinedClick}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={Contact}
                    alt=""
                    className="h-auto w-[25vw]  md:w-[9vw]"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-[3vw] md:gap-0 justify-center items-center h-[60vh] md:ml-[2vw] md:pt-0">
              <div className="block pl-[2vw] md:pl-0 p-[5vw] md:p-0  md:mt-[22vw] w-[80vw] md:w-[45vw]">
                <div className="text-[5vw] ml-[0.4vw] md:text-[1.8vw] font-bold text-white">
                  PRODUCTS
                </div>
                <div
                  className="relative w-full z-[100] mt-[0.4vw] text-[0.1vw] md:text-base lg:text-xl xl:text-2xl"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="flex flex-col flex-wrap md:-mx-1 z-[100]">
                    {items.map((item, index) => (
                      <div
                        key={index}
                        className={`m-[0.2vw] md:m-[0.5vw] py-[0.5vw] px-[0.1vw] md:p-[0vw] mb-2 ${
                          currentItemIndex === index ? style : ""
                        }`}
                        onMouseEnter={() => handleItemMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleItemClick(item.link)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="flex flex-row gap-[1vw] justify-center items-center">
                          <div className="w-[4vw] md:w-[2vw]">
                            {currentItemIndex === index && (
                              <img
                                src={Arrow}
                                alt=""
                                className="w-[6vw] h-[3vw] md:!w-[3vw] md:h-[2vw]"
                              />
                            )}
                          </div>
                          <div className="flex flex-row justify-between w-full">
                            <h1
                              className={` text-white ${
                                currentItemIndex === index
                                  ? "text-[3.5vw] md:!text-[1.8vw]"
                                  : "text-[3vw] md:text-[1.4vw]"
                              }`}
                            >
                              {item.title}
                            </h1>
                            <h1
                              className={`text-white text-end ${
                                currentItemIndex === index
                                  ? "text-[2vw] md:!text-[1.2vw]"
                                  : "text-[1.8vw] md:text-[0.95vw]"
                              }`}
                            >
                              {item.description}
                            </h1>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="visible md:invisible  md:w-0 md:h-0 !w-[15vw] h-[10vw] pb-[23vh] ">
                <img src={mobileAsset} alt="" className="w-[100vw] h-[45vw]" />
              </div>
            </div>
          </div>

          {/* Laptop Screen Only */}

          {hoveredImage && hoveredImageOutput && (
            <div
              className="invisible md:visible w-0 h-0 md:w-full md:h-full md:absolute md:flex md:justify-center md:items-center md:top-[4vw] md:z-[100] pointer-events-none"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="md:fixed block text-center relative  transform left-1/2 translate-x-[-3vw]">
                <div className="w-[18vw] mx-auto relative ">
                  <img
                    src={hoveredImage}
                    alt=""
                    className="w-full h-full !shadow-2xl !shadow-black "
                  />
                </div>
              </div>
            </div>
          )}

          {hoveredImageOutput && (
            <>
              {/* Right Modal */}
              <div
                className="invisible md:visible w-0 h-0 md:w-full md:h-screen md:max-w-[41
                vw] mx-auto  z-1000"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="h-full flex justify-start items-start relative ">
                  <img
                    src={hoveredImageOutput}
                    alt=""
                    className="object-cover max-w-[100vw] h-[100vh] !shadow-2xl !shadow-black"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* MOBILE SCREEN ONLY */}

      <div
        className={`visible md:invisible w-full h-full md:w-0 md:h-o md:transition-all md:duration-[2s] text-black ${
          isModalOpen ? "md:transform md:translate-x-[55vw]" : ""
        }`}
        style={{
          backgroundColor: isModalOpen ? "bg-[#FFFDF3]" : "",
          fontFamily: "Poppins",
        }}
      >
        <div
          className="w-[100vw] bg-[#E10600] h-[6vw] flex justify-center items-center text-white text-[1.55vw] cursor-pointer"
          onClick={() => {
            window.open("https://forms.gle/8ainDg6M5yNWvHLd6", "_blank");
          }}
        >
          We're launching our self-service platform in September.{"  "}
          <span className="text-white hover:underline cursor-pointer px-[0.3vw]">
            Click HERE
          </span>
          {"   "}
          to join our Early Access program and get $10 in free credits.
        </div>
        <div
          className={`relative w-full !h-screen flex flex-col md:flex-row justify-start items-start md:text-[1.25rem] lg:text-[1.35rem] xl:text-[2.50rem] overflow-hidden`}
        >
          <div className="relative flex flex-col flex-wrap md:-mx-1 z-[100]">
            <div className="top-0 bg-white left-0 z-10">
              <div className="flex w-[100vw] justify-between p-[1.1vw] pl-[0.5vw] pt-[0.5vw]">
                <div className="flex gap-[2vw]">
                  <div
                    className="flex pl-[0.4vw] pt-[4vw]"
                    onClick={toggleModal}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={ThreeArrow} alt="" className="w-[7vw] h-[5vw]" />
                  </div>
                  <div
                    className="flex pt-[0.5vw]"
                    onClick={handleLogoClick}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={NewLogo} alt="" className="w-[50vw] h-auto " />
                  </div>
                </div>

                <div
                  className="flex justify-end items-end pb-[1vw]"
                  onClick={handleCombinedClick}
                  style={{ cursor: "pointer" }}
                >
                  <img src={Contact} alt="" className="h-auto w-[27vw]" />
                </div>
              </div>
            </div>
            {/* Gradient Background for Mobile */}
            <div className="absolute visible md:invisible w-full h-full gradient-div-home-mobile" />

            {/* Main Content */}
            <div className="relative z-[200]">
              <img
                src={unleashMobile}
                alt=""
                className="left-[-12vw] w-[150vw] "
              />

              <div className="relative flex gap-[3vw] md:gap-0 justify-center items-center md:ml-[2vw] md:pt-0">
                <div className="block pl-[2vw] md:pl-0 sm:pt-[0vw] p-[5vw] md:p-0 md:mt-[22vw] w-[100vw] md:w-[45vw]">
                  <div className="text-[5vw] ml-[0.4vw] md:text-[1.8vw] font-bold text-white">
                    PRODUCTS
                  </div>
                  <div
                    className="w-full z-[100] mt-[0.4vw] text-[0.1vw] md:text-base lg:text-xl xl:text-2xl"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="flex flex-col flex-wrap md:-mx-1 z-[100]">
                      {items.map((item, index) => (
                        <div
                          key={index}
                          className={`m-[0.2vw] md:m-[0.5vw] py-[0.5vw] px-[0.1vw] md:p-[0vw] mb-2 ${
                            currentItemIndex === index ? style : ""
                          }`}
                          onMouseEnter={() => handleItemMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                          onTouchStart={(e) => {
                            e.stopPropagation();
                            handleItemMouseEnter(index);
                          }}
                          onTouchEnd={(e) => {
                            e.stopPropagation();
                            handleMouseLeave();
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleItemClick(item.link);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="flex flex-row gap-[1vw] justify-center items-center">
                            <div className="w-[4vw] md:w-[2vw]">
                              {currentItemIndex === index && (
                                <img
                                  src={Arrow}
                                  alt=""
                                  className="w-[6vw] h-[3vw] md:!w-[3vw] md:h-[2vw]"
                                />
                              )}
                            </div>
                            <div className="flex flex-row justify-between w-full">
                              <h1
                                className={`text-white ${
                                  currentItemIndex === index
                                    ? "text-[4vw] md:!text-[1.8vw]"
                                    : "text-[3.5vw] md:text-[1.4vw]"
                                }`}
                              >
                                {item.title}
                              </h1>
                              <h1
                                className={`text-white text-end ${
                                  currentItemIndex === index
                                    ? "text-[2.6vw] md:!text-[1.2vw]"
                                    : "text-[2.5vw] md:text-[0.95vw]"
                                }`}
                              >
                                {item.description}
                              </h1>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className="visible md:invisible md:w-0 md:h-0 !w-[15vw] h-[10vw] sm:pb-[35vh] pb-[23vh] ">
                  <img
                    src={mobileAsset}
                    alt=""
                    className="w-[100vw] h-[45vw]"
                  />
                </div> */}
              </div>

              <div className="relative visible md:invisible md:w-0 md:h-0 w-full text-[0.2rem] md:text-base lg:text-xl xl:text-2xl dynamic-bg">
                {hoveredImage && hoveredImageOutput && (
                  <div
                    className="absolute sm:w-[35vw] sm:top-[8vh] w-[40vw] top-[13vh] h-full flex justify-start items-start left-0 z-20 md:hidden"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="block text-center">
                      <div className="w-full mx-auto ">
                        <img
                          src={hoveredImage}
                          alt=""
                          className="w-full h-full shadow-black shadow-2xl"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {hoveredImageOutput && (
                  <div
                    className="h-full flex justify-end items-end right-0 "
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="flex items-end justify-end sm:w-[55vw] w-[63vw] h-[50vh]">
                      <img
                        src={hoveredImageOutput}
                        alt=""
                        className="object-contain shadow-black shadow-2xl"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className={`side-navbar z-[250] fixed top-0 left-0 w-full  md:w-[55vw] h-[100vh] flex transform transition-transform duration-5000  md:text-[0.9vw] p ${
            isModalOpen ? "translate-x-0" : "-translate-x-full"
          }`}
          style={{ fontFamily: "Montserrat, sans-serif" }}
        >
          {/* Left Sidebar */}
          <div
            className="w-[85vw]  md:w-[72vw] max-h-[100vh] scroll-ml-6 bg-[#f0f0f0] overflow-auto scrollbar-custom left-side-navbar"
            style={{ scrollbarWidth: "thin" }}
          >
            {[
              { id: "about", component: <About /> },
              { id: "products", component: <Products /> },
              { id: "services", component: <Services /> },
              { id: "backers", component: <Backers /> },
              { id: "clients", component: <Client /> },
              { id: "contact", component: <ContactForm /> },
              { id: "FAQs", component: <Faq /> },
              { id: "press", component: <Press /> },
              { id: "legal", component: <Legal /> },
            ].map(({ id, component }) => (
              <section key={id} id={id}>
                {component}
              </section>
            ))}
          </div>

          {/* Right Sidebar (Visible on Desktop) */}

          <div
            className="w-[15vw] items-center md:w-[28vw] bg-white md:block right-side-navbar"
            style={{ display: "block" }}
          >
            <div className="p-[1.2vw]  pr-[14vw] md:pr-[0.1vw]">
              {/* Close Button */}
              <button
                onClick={toggleModal}
                className="text-gray-500 hover:text-gray-700 pl-[2vw] md:pl-[10vw]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Menu Items */}
            <div className="block bg-fixed bg-white">
              {[
                { id: "about", label: "About Us", img: AboutLogo },
                { id: "products", label: "Products", img: ProductsLogo },
                { id: "services", label: "Services", img: servicesLogo },
                { id: "backers", label: "Backers", img: BackersLogo },
                { id: "clients", label: "Clients", img: ClientLogo },
                { id: "contact", label: "Contact", img: Email },
                { id: "FAQs", label: "FAQs", img: FAQsLogo },
                { id: "press", label: "Press", img: PressLogo },
                { id: "legal", label: "Legal", img: LegalLogo },
              ].map(({ id, label, img }) => (
                <div
                  key={id}
                  className={`flex  items-center md:py-[0.4vw] py-[1.5vw] md:pl-[2vw] pl-[3vw] md:transform ${
                    selectedMenuItem === id
                      ? "bg-[#f0f0f0]"
                      : "hover:bg-[#f0f0f0]"
                  }`}
                >
                  <div
                    className={`flex md:flex md:gap-[1vw]  md:items-center  md:transform md:transition-transform md:duration-[1000ms] md:hover:translate-x-2 md: hover:bg-[#f0f0f0] ${
                      selectedMenuItem === id ? "md:translate-x-2" : ""
                    }`}
                    onClick={() =>
                      handleMenuItemClick(id, () => scrollToSection(id))
                    }
                  >
                    <img
                      src={img}
                      alt={label}
                      className="md:h-[2vw] md:w-[2vw] h-[9vw] w-[9vw]"
                    />
                    <span className="invisible md:visible w-0 h-0 md:w-full md:h-full">
                      {label}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
